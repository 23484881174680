import { emojiData } from '../Emoji/data.js'

export default {
  name: 'Emoji',
  data () {
    return {
      list: emojiData
    }
  },
  mounted () {
  },
  methods: {
    methodsEmojiUrl (name) {
      return `https://cdn.papaen.com/resource/emoji/${name}.png`
    },
    chooseEmoji (name) {
      this.$emit('chooseEmoji', name)
    }
  }
}
