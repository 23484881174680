let emojiData = [
  {'face_name': '[\u9f87\u7259]', 'face_id': '001'},
  {
    'face_name': '[\u8c03\u76ae]',
    'face_id': '002'
  }, {'face_name': '[\u6d41\u6c57]', 'face_id': '003'}, {
    'face_name': '[\u5077\u7b11]',
    'face_id': '004'
  }, {'face_name': '[\u518d\u89c1]', 'face_id': '005'}, {
    'face_name': '[\u6572\u6253]',
    'face_id': '006'
  }, {'face_name': '[\u64e6\u6c57]', 'face_id': '007'}, {
    'face_name': '[\u732a\u5934]',
    'face_id': '008'
  }, {'face_name': '[\u73ab\u7470]', 'face_id': '009'}, {
    'face_name': '[\u6d41\u6cea]',
    'face_id': '010'
  }, {'face_name': '[\u5927\u54ed]', 'face_id': '011'}, {
    'face_name': '[\u5618]',
    'face_id': '012'
  }, {'face_name': '[\u9177]', 'face_id': '013'}, {
    'face_name': '[\u6293\u72c2]',
    'face_id': '014'
  }, {'face_name': '[\u59d4\u5c48]', 'face_id': '015'}, {
    'face_name': '[\u4fbf\u4fbf]',
    'face_id': '016'
  }, {'face_name': '[\u70b8\u5f39]', 'face_id': '017'}, {
    'face_name': '[\u83dc\u5200]',
    'face_id': '018'
  }, {'face_name': '[\u53ef\u7231]', 'face_id': '019'}, {
    'face_name': '[\u8272]',
    'face_id': '020'
  }, {'face_name': '[\u5bb3\u7f9e]', 'face_id': '021'}, {
    'face_name': '[\u5f97\u610f]',
    'face_id': '022'
  }, {'face_name': '[\u5410]', 'face_id': '023'}, {
    'face_name': '[\u5fae\u7b11]',
    'face_id': '024'
  }, {'face_name': '[\u6012]', 'face_id': '025'}, {
    'face_name': '[\u5c34\u5c2c]',
    'face_id': '026'
  }, {'face_name': '[\u60ca\u6050]', 'face_id': '027'}, {
    'face_name': '[\u51b7\u6c57]',
    'face_id': '028'
  }, {'face_name': '[\u7231\u5fc3]', 'face_id': '029'}, {
    'face_name': '[\u793a\u7231]',
    'face_id': '030'
  }, {'face_name': '[\u767d\u773c]', 'face_id': '031'}, {
    'face_name': '[\u50b2\u6162]',
    'face_id': '032'
  }, {'face_name': '[\u96be\u8fc7]', 'face_id': '033'}, {
    'face_name': '[\u60ca\u8bb6]',
    'face_id': '034'
  }, {'face_name': '[\u7591\u95ee]', 'face_id': '035'}, {
    'face_name': '[\u56f0]',
    'face_id': '036'
  }, {'face_name': '[\u4e48\u4e48\u54d2]', 'face_id': '037'}, {
    'face_name': '[\u61a8\u7b11]',
    'face_id': '038'
  }, {'face_name': '[\u7231\u60c5]', 'face_id': '039'}, {
    'face_name': '[\u8870]',
    'face_id': '040'
  }, {'face_name': '[\u6487\u5634]', 'face_id': '041'}, {
    'face_name': '[\u9634\u9669]',
    'face_id': '042'
  }, {'face_name': '[\u594b\u6597]', 'face_id': '043'}, {
    'face_name': '[\u53d1\u5446]',
    'face_id': '044'
  }, {'face_name': '[\u53f3\u54fc\u54fc]', 'face_id': '045'}, {
    'face_name': '[\u62b1\u62b1]',
    'face_id': '046'
  }, {'face_name': '[\u574f\u7b11]', 'face_id': '047'}, {
    'face_name': '[\u98de\u543b]',
    'face_id': '048'
  }, {'face_name': '[\u9119\u89c6]', 'face_id': '049'}, {
    'face_name': '[\u6655]',
    'face_id': '050'
  }, {'face_name': '[\u5927\u5175]', 'face_id': '051'}, {
    'face_name': '[\u53ef\u601c]',
    'face_id': '052'
  }, {'face_name': '[\u5f3a]', 'face_id': '053'}, {
    'face_name': '[\u5f31]',
    'face_id': '054'
  }, {'face_name': '[\u63e1\u624b]', 'face_id': '055'}, {
    'face_name': '[\u80dc\u5229]',
    'face_id': '056'
  }, {'face_name': '[\u62b1\u62f3]', 'face_id': '057'}, {
    'face_name': '[\u51cb\u8c22]',
    'face_id': '058'
  }, {'face_name': '[\u7c73\u996d]', 'face_id': '059'}, {
    'face_name': '[\u86cb\u7cd5]',
    'face_id': '060'
  }, {'face_name': '[\u897f\u74dc]', 'face_id': '061'}, {
    'face_name': '[\u5564\u9152]',
    'face_id': '062'
  }, {'face_name': '[\u74e2\u866b]', 'face_id': '063'}, {
    'face_name': '[\u52fe\u5f15]',
    'face_id': '064'
  }, {'face_name': '[OK]', 'face_id': '065'}, {
    'face_name': '[\u7231\u4f60]',
    'face_id': '066'
  }, {'face_name': '[\u5496\u5561]', 'face_id': '067'}, {
    'face_name': '[\u6708\u4eae]',
    'face_id': '068'
  }, {'face_name': '[\u5200]', 'face_id': '069'}, {
    'face_name': '[\u53d1\u6296]',
    'face_id': '070'
  }, {'face_name': '[\u5dee\u52b2]', 'face_id': '071'}, {
    'face_name': '[\u62f3\u5934]',
    'face_id': '072'
  }, {'face_name': '[\u5fc3\u788e\u4e86]', 'face_id': '073'}, {
    'face_name': '[\u592a\u9633]',
    'face_id': '074'
  }, {'face_name': '[\u793c\u7269]', 'face_id': '075'}, {
    'face_name': '[\u76ae\u7403]',
    'face_id': '076'
  }, {'face_name': '[\u9ab7\u9ac5]', 'face_id': '077'}, {
    'face_name': '[\u6325\u624b]',
    'face_id': '078'
  }, {'face_name': '[\u95ea\u7535]', 'face_id': '079'}, {
    'face_name': '[\u9965\u997f]',
    'face_id': '080'
  }, {'face_name': '[\u56f0]', 'face_id': '081'}, {
    'face_name': '[\u5492\u9a82]',
    'face_id': '082'
  }, {'face_name': '[\u6298\u78e8]', 'face_id': '083'}, {
    'face_name': '[\u62a0\u9f3b]',
    'face_id': '084'
  }, {'face_name': '[\u9f13\u638c]', 'face_id': '085'}, {
    'face_name': '[\u7cd7\u5927\u4e86]',
    'face_id': '086'
  }, {'face_name': '[\u5de6\u54fc\u54fc]', 'face_id': '087'}, {
    'face_name': '[\u6253\u54c8\u6b20]',
    'face_id': '088'
  }, {'face_name': '[\u5feb\u54ed\u4e86]', 'face_id': '089'}, {
    'face_name': '[\u5413]',
    'face_id': '090'
  }, {'face_name': '[\u7bee\u7403]', 'face_id': '091'}, {
    'face_name': '[\u4e52\u4e53]',
    'face_id': '092'
  }, {'face_name': '[NO]', 'face_id': '093'}, {
    'face_name': '[\u8df3\u8df3]',
    'face_id': '094'
  }, {'face_name': '[\u6004\u706b]', 'face_id': '095'}, {
    'face_name': '[\u8f6c\u5708]',
    'face_id': '096'
  }, {'face_name': '[\u78d5\u5934]', 'face_id': '097'}, {
    'face_name': '[\u56de\u5934]',
    'face_id': '098'
  }, {'face_name': '[\u8df3\u7ef3]', 'face_id': '099'}, {
    'face_name': '[\u6fc0\u52a8]',
    'face_id': '100'
  }, {'face_name': '[\u8857\u821e]', 'face_id': '101'}, {
    'face_name': '[\u732e\u543b]',
    'face_id': '102'
  }, {'face_name': '[\u5de6\u592a\u6781]', 'face_id': '103'}, {
    'face_name': '[\u53f3\u592a\u6781]',
    'face_id': '104'
  }, {'face_name': '[\u95ed\u5634]', 'face_id': '105'}, {
    'face_name': '[\u732b\u54aa]',
    'face_id': '106'
  }, {'face_name': '[\u7ea2\u53cc\u559c]', 'face_id': '107'}, {
    'face_name': '[\u97ad\u70ae]',
    'face_id': '108'
  }, {'face_name': '[\u7ea2\u706f\u7b3c]', 'face_id': '109'}, {
    'face_name': '[\u9ebb\u5c06]',
    'face_id': '110'
  }, {'face_name': '[\u9ea6\u514b\u98ce]', 'face_id': '111'}, {
    'face_name': '[\u793c\u54c1\u888b]',
    'face_id': '112'
  }, {'face_name': '[\u4fe1\u5c01]', 'face_id': '113'}, {
    'face_name': '[\u8c61\u68cb]',
    'face_id': '114'
  }, {'face_name': '[\u5f69\u5e26]', 'face_id': '115'}, {
    'face_name': '[\u8721\u70db]',
    'face_id': '116'
  }, {'face_name': '[\u7206\u7b4b]', 'face_id': '117'}, {
    'face_name': '[\u68d2\u68d2\u7cd6]',
    'face_id': '118'
  }, {'face_name': '[\u5976\u74f6]', 'face_id': '119'}, {
    'face_name': '[\u9762\u6761]',
    'face_id': '120'
  }, {'face_name': '[\u9999\u8549]', 'face_id': '121'}, {
    'face_name': '[\u98de\u673a]',
    'face_id': '122'
  }, {'face_name': '[\u5de6\u8f66\u5934]', 'face_id': '124'}, {
    'face_name': '[\u8f66\u53a2]',
    'face_id': '125'
  }, {'face_name': '[\u53f3\u8f66\u5934]', 'face_id': '126'}, {
    'face_name': '[\u591a\u4e91]',
    'face_id': '127'
  }, {'face_name': '[\u4e0b\u96e8]', 'face_id': '128'}, {
    'face_name': '[\u7f8e\u5143]',
    'face_id': '129'
  }, {'face_name': '[\u718a\u732b]', 'face_id': '130'}, {
    'face_name': '[\u706f\u6ce1]',
    'face_id': '131'
  }, {'face_name': '[\u98ce\u8f66]', 'face_id': '132'}, {
    'face_name': '[\u95f9\u949f]',
    'face_id': '133'
  }, {'face_name': '[\u96e8\u4f1e]', 'face_id': '134'}, {
    'face_name': '[\u5f69\u7403]',
    'face_id': '135'
  }, {'face_name': '[\u94bb\u6212]', 'face_id': '136'}, {
    'face_name': '[\u6c99\u53d1]',
    'face_id': '137'
  }, {'face_name': '[\u7eb8\u5dfe]', 'face_id': '138'}, {
    'face_name': '[\u624b\u67aa]',
    'face_id': '140'
  }, {'face_name': '[\u9752\u86d9]', 'face_id': '141'}]

export { emojiData }
