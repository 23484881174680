import Emoji from '../Emoji/Emoji.vue';

import {
    MsgUserHandUp,
    MsgUserHandDown,
} from '@/views/Live/constants/customMsgType';
import { mapActions, mapState } from 'vuex';

import EXT_IM from '@/views/Live/ext/im';
import EXT_RTC from '@/views/Live/ext/rtc';

export default {
    data() {
        return {
            inputMessage: '',
            maxLength: '140',
            isShowEmojiPanel: false,
            defaultAvatar: require('@/assets/img/common/avatar.png'),
        };
    },
    mounted() {},
    computed: {
        inputMessageLength() {
            return this.inputMessage.length;
        },
        canHandUp() {
            return !this.isConnect && parseInt(this.classStatus) === 1;
        },
        canHandDown() {
            return this.isConnect && parseInt(this.classStatus) === 1;
        },
        ...mapState({
            roomID: (state) => state.live.roomID,
            groupInfo: (state) => state.live.groupInfo,
            msgs: (state) => state.live.msgs,
            groupInfoMsg: (state) => state.live.groupInfo,
            isMute: (state) => state.live.isMute,
            isConnect: (state) => state.live.isConnect,
            classStatus: (state) => state.live.classStatus,
            groupMute: (state) => state.live.groupMute,
            currentTeacher: (state) => state.live.currentTeacher,
            liveMode: (state) => state.live.liveMode,
            nextMsgSeq: (state) => state.im.nextMsgSeq,
            localStream: (state) => state.rtc.localStream,
            trtcClient: (state) => state.rtc.trtcClient,
            isCompleted: (state) => state.im.isCompleted,
        }),
    },
    props: {
        isReplay: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sendMsg() {
            if (this.inputMessage.replace(/\s+/g, '')) {
                this.$emit('sendMsg', this.inputMessage);
                this.inputMessage = '';
            } else {
                this.$message.error('不能发送空消息');
            }
        },
        inputChange() {
            this.uploadPic();
        },
        handleEmoji(name) {
            this.inputMessage += name;
            this.isShowEmojiPanel = false;
        },
        showEmojiPanel() {
            this.isShowEmojiPanel = true;
        },
        editNotification() {
            this.setIsShowNotificationBox(true);
        },
        requestConnect() {
            let msg = {
                type: 'c2c',
                data: MsgUserHandUp,
                targetId: this.currentTeacher.uuid,
            };
            EXT_IM.sendCustomMessage(msg).then(() => {
                console.log('举手成功');
                this.setIsConnect(true);
            });
        },
        cancleRequest() {
            let msg = {
                type: 'c2c',
                data: MsgUserHandDown,
                targetId: this.currentTeacher.uuid,
            };
            EXT_IM.sendCustomMessage(msg).then(() => {
                console.log('取消举手');
                if (this.localStream) {
                    EXT_RTC.stopPush(this.trtcClient, this.localStream);
                    this.setLocalStream(null);
                }
                this.setIsConnect(false);
                this.setMicStatus(2);
            });
        },
        getHistoryMsg() {
            let conversationID = 'GROUP' + this.roomID;

            EXT_IM.getHistoryMsg(
                window.tim,
                conversationID,
                this.groupInfo.NextMsgSeq - 1
            ).then((res) => {
                let list = res.data;
                this.dealHistoryMsg(list);
            });
        },
        loadMoreMsg() {
            this.$emit('loadMoreMsg');
        },
        ...mapActions([
            'setMicStatus',
            'setIsConnect',
            'setLocalStream',
            'setIsShowNotificationBox',
        ]),
    },
    components: {
        Emoji,
    },
};