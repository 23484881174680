import store from '@/store'

export const C_G_CONNECT_USER = 1        // 老师通知学生当前的连麦用户
export const C_G_HAND_UP_USERS = 2         // 老师通知学生当前的举手用户
export const C2C_USER_HAND_UP = 3        // 学生申请发言
export const C2C_USER_HAND_DOWN = 4      // 学生取消发言申请
export const C2C_OWNER_ALLOW_CONNECT = 5 // 老师同意发言请求
export const C2C_OWNER_CLOSE_CONNECT = 6 // 老师结束学生发言
export const GROUP_CLASS_OVER = 7        // 老师下课
export const GROUP_CLASS_BEGIN = 8      // 老师上课
export const C2C_USER_ENTER = 9         // 老师通知新进入的用户上课状态(举手也列表，发言列表，流的状态摄像头还是屏幕共享)
export const C_G_OPEN_CAMERA = 10        // 开启摄像头   两种类型 广播（开始上课）和点对点  （新学员加入）
export const C_G_OPEN_SHARE = 11         // 开启屏幕共享 两种类型 广播（开始上课）和点对点  （新学员加入）
export const GROUP_MUTE = 12         // 全员禁言状态
export const C2C_TELL_ENTER = 13   // 学生广播自己进入教室
export const C_G_ONLINE = 16

let uuid = localStorage.authorization && JSON.parse(localStorage.authorization).sign || sessionStorage.authorization && JSON.parse(sessionStorage.authorization).sign

export function MsgONLine() {
  return {
    type: C_G_ONLINE,
    ext: 'TXNotificationExt'
  }
}

export function MsgConnectUser () {
  return {
    type: C_G_CONNECT_USER,
    ext: 'TXNotificationExt',
    uids: getUids('isConnected')
  }
}

export function MsgHandUpUserList () {
  return {
    type: C_G_HAND_UP_USERS,
    ext: 'TXNotificationExt',
    uids: getUids('handUp')
  }
}

export function MsgUserEnter (streamType) {
  return {
    type: C2C_USER_ENTER,
    ext: 'TXNotificationExt',
    raiseUids: getUids('handUp'),
    actorsUids: getUids('isConnected'),
    streamType: streamType
  }
}

export let MsgUserHandUp = {
  type: C2C_USER_HAND_UP,
  ext: 'TXNotificationExt',
  uids: [localStorage.authorization && JSON.parse(localStorage.authorization).sign || sessionStorage.authorization && JSON.parse(sessionStorage.authorization).sign
  ]
}

export let MsgUserHandDown = {
  type: C2C_USER_HAND_DOWN,
  ext: 'TXNotificationExt',
  uids: [localStorage.authorization && JSON.parse(localStorage.authorization).sign || sessionStorage.authorization && JSON.parse(sessionStorage.authorization).sign
  ]
}

export let MsgTeacherAllowConnect = {
  type: C2C_OWNER_ALLOW_CONNECT,
  ext: 'TXNotificationExt',
}

export let MsgTeacherCloseConnect = {
  type: C2C_OWNER_CLOSE_CONNECT,
  ext: 'TXNotificationExt',
}


export let MsgTeacherStartClass = {
  type: GROUP_CLASS_BEGIN,
  ext: 'TXNotificationExt',
}

export let MsgTeacherEndClass = {
  type: GROUP_CLASS_OVER,
  ext: 'TXNotificationExt',
}

export function MsgBoard (msg) {
  return {
    ext: 'TXWhiteBoardExt',
    boardData: msg
  }
}


export function MsgOpenCamera () {
  return {
    type: C_G_OPEN_CAMERA,
    ext: 'TXNotificationExt',
  }
}

export function MsgOpenShare () {
  return {
    type: C_G_OPEN_SHARE,
    ext: 'TXNotificationExt',
  }
}


export function UsserTellEnter () {
  let uuid = localStorage.authorization && JSON.parse(localStorage.authorization).sign || sessionStorage.authorization && JSON.parse(sessionStorage.authorization).sign
  return {
    type: C2C_TELL_ENTER,
    ext: 'TXNotificationExt',
    uids: [uuid]
  }
}

function getUids (type) {
  console.log(type)
  let uids = []
  store.state.tic.userList.forEach(ele => {
    if (ele[type]) {
      uids.push(ele.userID)
    }
  })
  console.log(uids)
  return uids
}



