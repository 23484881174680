import * as Config from '@/config/live'
import { formatMoment } from '@/utils/utils'
/* eslint-disable */
export default {

  // 创建IM实例
  getIMInstance () {
    let options = {
      SDKAppID: parseInt(Config.sdkAppId)
    }
    return TIM.create(options)

  },
  // 登录
/**
 * @param {string}: userID
 * @param {string}: userSig
 */
handleLoginIM (config) {
    return this.getIMInstance().login({...config})
  },

  // 加入IM群组
  handleJoinIMGroup (tim, groupID) {
    let option = {
      groupID: groupID + '',
      applyMessage: '',
      type: TIM.TYPES.GRP_PUBLIC
    }
    return tim.joinGroup(option)
  },

  // 获取成员列表
  getMemberList (groupId, count, offset) {
    return window.tim.getGroupMemberList({groupID: groupId + '', count: count, offset: offset})
  },

  getGroupMemberList (groupId, count, offset) {
    let list = []
    return this.getMemberList(groupId, count, offset).then(({data: {memberList}}) => {
      list = list.concat(memberList)
      if (memberList.length === (count + offset)) {
        this.getGroupMemberList(groupId, count, offset + count)
      } else {
        return new Promise(resolve => {
          resolve(list)
        })
      }
    })
  },



  // 处理群文本消息
  handleGroupTextMsg (text) {
    return new Promise((resolve, reject) => {
      var exgFace = /\[[^[\]]{1,3}\]/mg
      if (exgFace.test(text)) {
        let emotionsArr = Array.from(new Set(text.match(exgFace)))
        let html = ''
        emotionsArr.forEach(ele => {
          html = `<img class='emoji-pic' width='24' height='24' src='${Config.EMOJIBASEURL}${ele}.png'>`
          ele = ele.replace(/\[|]/g, '')
          let reg = new RegExp('\\[' + ele + '\\]', 'g')
          text = text.replace(reg, html)
        })
      }
      resolve(text)
    })
  },

  //解析图片消息元素
  handleImageMsgToHtml (content) {
    return new Promise((resolve, reject) => {
      let smallImage = content.imageInfoArray[0]//小图
      let bigImage = content.imageInfoArray[1]//大图
      let oriImage = content.imageInfoArray[2] //原图
      if (!bigImage) {
        bigImage = smallImage
      }
      if (!oriImage) {
        oriImage = smallImage
      }
      let bigPicUrl = oriImage.imageUrl
      let img = `<img class="chat_img" draggable="false"  bigimgsrc="${bigPicUrl}" style="max-width: 150px" src="${smallImage.imageUrl}">`
      resolve(img)
    })
  },

  //
  getGroupList (tim) {
    return tim.getGroupList()
  },

  //创建文本消息

  createTextMessage (text, groupId) {
    return window.tim.createTextMessage({
      to: groupId + '',
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        text: text
      }
    })
  },
  handleTextMsg (msg) {
    let text = msg.payload.text
    return new Promise((resolve, reject) => {
      var exgFace = /\[[^[\]]{1,3}\]/mg
      if (exgFace.test(text)) {
        let emotionsArr = Array.from(new Set(text.match(exgFace)))
        let html = ''
        emotionsArr.forEach(ele => {
          html = `<img class='emoji-pic' width='24' height='24' src='${Config.EMOJIBASEURL}${ele}.png'>`
          ele = ele.replace(/\[|]/g, '')
          let reg = new RegExp('\\[' + ele + '\\]', 'g')
          text = text.replace(reg, html)
        })
      }
      resolve(text)
    })
  },

  // 创建图片消息
  createImgMessage (tim, dom, groupId, callback) {
    return tim.createImageMessage({
      to: groupId,
      conversationType: TIM.TYPES.CONV_GROUP,  // 会话类型，取值TIM.TYPES.CONV_C2C（端到端会话） 或 TIM.TYPES.CONV_GROUP（群组会话）
      payload: {
        file: dom
      },
      onProgress: callback(event)
    })
  },

  // 创建自定义消息
  createCustomMessage (msg) {
    let data = JSON.stringify(msg.data)
    let ext = msg.extension
    let targetId = msg.type === 'c2c' ? msg.targetId : window.roomID
    return window.tim.createCustomMessage({
      to: targetId + '',
      conversationType: msg.type === 'c2c' ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,  // 会话类型，取值TIM.TYPES.CONV_C2C（端到端会话） 或 TIM.TYPES.CONV_GROUP（群组会话）
      payload: {
        data: data,
        description: '',
        extension: ext === 'TXWhiteBoardExt' ? 'TXWhiteBoardExt' : ''
      }
    })
  },


  // 发送消息
  sendMessage ( message) {
    return tim.sendMessage(message)
  },

  // 发送文本消息
  sendTextMessage (text, groupId) {
    return this.sendMessage(this.createTextMessage(text, groupId))
  },

  // 发送图片消息
  sendImageMessage ( dom, groupId, callback) {
    return this.sendMessage(this.createImgMessage( dom, groupId, callback))
  },

  // 发送自定义消息
  sendCustomMessage (msg) {
    return this.sendMessage(this.createCustomMessage(msg))
  },


  //设置群组资料
  modifyGroup (tim, options) {
    return tim.updateGroupProfile({
      groupID: options.groupId,
      notification: options.notification,
    })
  },


  //读取群组基本资料
  getGroupInfo (groupId) {
    return window.tim.getGroupProfile({
      groupID: groupId,
    }).catch(e => {
    })
  },


  // 读取群成员信息
  getGroupMemberProfile (tim, groupId, userList) {
    return tim.getGroupMemberProfile({
      groupID: groupId,
      userIDList: userList,
    })
  },

  // 获取成员信息  (非群组内  例如获取退出群组的成员的信息)
  getUserProfile (tim, userList) {
    let options = {
      userIDList: userList
    }
    return tim.getUserProfile(options)
  },


  // 获取历史消息
  getHistoryMsg (tim, conversationID, nextRegMessageID) {
    return tim.getMessageList({
      conversationID: conversationID,
      nextReqMessageID: nextRegMessageID,
      count: 15,
    })
  },

  //  加入聊天室
  joinGroup (tim, groupId) {
    return tim.joinGroup({
      groupID: groupId.toString()
    })
  },

  // 处理历史消息
  dealHistoryMsg (msgList) {
    return new Promise((resolve, reject) => {
      let msg = []
      msgList.forEach((elem, index) => {
        const nick = elem.nick
        const avatar = elem.avatar
        let identity = ''
        const time = elem.time
        const account = elem.from
        if (identity === 300) {
          if (JSON.parse(sessionStorage.currentTeacher).uuid === account) {
            identity = 'teacher'
          } else {
            identity = 'assistant'
          }
        } else {
          identity = ''
        }
        let type = elem.type
        let content = elem.payload
        if (type === 'TIMTextElem') {
          this.handleGroupTextMsg(content.text).then((text) => {
            msg.push({user: {nick, avatar, identity, account}, text, type: 'chat', time: time})
          })
        } else if (type === 'TIMImageElem') {
          this.handleImageMsgToHtml(content).then((imgHtml) => {
            let text = imgHtml
            msg.push({user: {nick, avatar, identity, account}, text, type: 'chat', time: time})
          })
        }
      })
      resolve(msg)
    })
  },

}
