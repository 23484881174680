<template>
  <div class="emoji-panel">
    <div class="emoji-content">
      <ul class="emoji-list">
        <li v-for="item in list" :key="item.face_id" @click="chooseEmoji(item.face_name)">
          <img :src="methodsEmojiUrl(item.face_name)" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import EmojiJs from './Emoij.js'

  export default EmojiJs
</script>

<style lang="less" scoped>
  .emoji-panel {
    width: 100%;

    & > .emoji-content {
      & > .emoji-list {
        width: 90%;
        height: 140px;
        padding: 10px 10px 10px 0;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        position: absolute;
        background: #fff;
        bottom: 40px;
        right: 10px;
        border: 1px solid #8c939d;

        & > li {
          width: 24px;
          height: 24px;
          list-style: none;
          padding: 1px;
          cursor: pointer;

          & > img {
            width: 100%;
          }
        }
      }

    }
  }
</style>
