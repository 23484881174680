<template>
  <div class="chat-list-page">
    <div class="chat-announcement" @click="editNotification" v-if="liveMode === 2&&!isReplay && groupInfoMsg && groupInfoMsg.notification">
      <div class="message_box" v-if="groupInfoMsg">
        <div class="announcement-info" >{{groupInfoMsg.notification}}</div>
      </div>
    </div>
    <div class="chat-list" :class="isReplay?'chat_list-replay': ''" id="msg_box">
      <div class="list_btn" @click="loadMoreMsg" v-show="!isCompleted">点击加载更多~</div>
      <template v-if="msgs.length>0">
        <div v-for="(msg,index) in msgs" :key="index">
          <template v-if="msg.type==='chat'">
            <div v-if="msg.send === 'personal'" class="chat-personal">
              <div class="msg" v-html="msg.content"></div>
              <div class="avatar">
                <img :src="msg.avatar||defaultAvatar" alt="">
              </div>
            </div>
            <div v-else class="chat-others">
              <div class="avatar">
                <img :src="msg.avatar||defaultAvatar" alt="">
              </div>
              <div class="con-box">
                <div class="user">
                  <div class="tag" v-if="msg.identity==='teacher'">老师</div>
                  <div class="tag" v-else-if="msg.identity==='assistant'">助教</div>
                  <div class="username">{{msg.send}}</div>
                </div>
                <div class="msg" v-html="msg.content"></div>
              </div>
            </div>
          </template>
          <template v-else-if="msg.type==='notify'">
            <div class="public-msg-box">
              <span class="chat-public">{{msg.content}}</span>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="msgs.length === 0 && isCompleted">
        <div class="no-content">
          <img src="../img/pic_talk.png" alt="">
          <span>还没有人发言~</span>
        </div>
      </template>
    <template
        v-if="!isMute && groupMute !== 'On'"
    >
      <i class="iconfont icon-papa-hand icon-talk_off" v-if="liveMode===2&&canHandUp"
         @click="requestConnect"></i>
      <i class="iconfont icon-papa-call icon-talk " v-else-if="canHandDown&&liveMode === 2" @click="cancleRequest"></i>
    </template>
    </div>
    <div class="chat-input" v-if="!isReplay">
      <div class="mute-status" v-show="isMute">
        您已被禁言
      </div>
      <div class="mute-status" v-show="groupMute === 'On'">
        课堂被禁言了
      </div>
      <textarea name="" id="" cols="30" rows="10" maxlength="1000" v-model="inputMessage"
                @keyup.enter="sendMsg"></textarea>
      <Emoji v-if="isShowEmojiPanel" @chooseEmoji="handleEmoji"></Emoji>
      <div class="tool-box" :class="isMute||groupMute==='On'?'gray':''">
        <div class="left"></div>
        <div class="right">
          <div class="emoji-icon" @click="showEmojiPanel">
            <i class="iconfont icon-papa-emoji"></i>
          </div>
          <div class="submit-btn" @click="sendMsg">发送</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ChatListJs from './chatList'

  export default ChatListJs
</script>

<style lang="less" scoped>
  .chat-list-page {
    width: 100%;
    height: 100%;
    position: relative;

    & > .chat-announcement {
      box-sizing: border-box;
      width: 100%;
      background: #f1f1f1;
      color: #B3B3B3;
      line-height: 21px;
      padding: 10px 6px;
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      .message_box {
        width: calc(100% - 32px);

        & > .announcement-info {
          font-size: 12px;
          text-align: left;
        }
      }


    }

    & > .chat-list {
      width: 100%;
      overflow: auto;
      height: calc(100% - 128px);
      padding-bottom: 50px;
      box-sizing: border-box;
      position: relative;

      .list_btn {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        font-size: 12px;
        color: #333;
      }

      & > div {
        & > .chat-personal, .chat-others {
          display: flex;
          padding: 0 12px;
          margin-top: 20px;
          align-items: flex-start;
          align-items: center;
          .msg {
            padding: 6px 8px;
            font-size: 12px;
            color: #333;
            /*line-height: 26px;*/
            margin-right: 12px;
            border-radius: 8px;
            background: #f5f5f5;
            float: left;
            text-align: left !important;
          }

          & > .avatar {
            width: 57px;
            height: 57px;
            overflow: hidden;
            min-width: 56px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          & > .con-box {
            margin-left: 10px;

            & > .user {
              display: flex;
              color: #999;
              font-size: 14px;
              margin-bottom: 5px;

              & > .username {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 10em;
                text-align: left;
              }

              & > .tag {
                width: 44px;
                height: 22px;
                text-align: center;
                font-size: 12px;
                line-height: 22px;
                border-radius: 2px;
                background: #dfeee8;
                color: #29D087;
                margin-right: 2px;
              }
            }
          }
        }

        & > .chat-personal {
          justify-content: flex-end;
        }

        & > .public-msg-box {
          display: flex;

          & > .chat-public {
            text-align: center;
            height: 30px;
            line-height: 30px;
            /*background: #ccc;*/
            padding: 0 10px;
            color: #555;
            border-radius: 3px;
            margin: 10px auto;
            font-size: 12px;
          }
        }
      }

      & > .no-content {
        width: 100%;
        text-align: center;
        line-height: 100px;
        color: #555;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 40%;
        margin-top: -30%;

        & > img {
          width: 178px;
        }

        & > span {
          margin-top: 20px;
          color: #ccc;
          font-size: 16px;
        }
      }

      & > .icon-talk {
        color: #FF3237;
        background: linear-gradient(124deg, rgba(255, 123, 120, 1), rgba(246, 96, 96, 1));
        box-shadow: 0px 6px 12px 0px rgba(255, 41, 41, 0.26);
      }

      & > .icon-talk_off {
        color: #29D087;
        box-shadow: 0px 6px 14px 0px rgba(31, 202, 143, 0.31);
      }

      & > .iconfont {
        font-size: 42px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        position: fixed;
        bottom: 200px;
        right: 10px;
        cursor: pointer;
      }

      & > .iconfont:before {
        margin: 0;
      }
    }

    .chat_list-replay {
      height: 100%;
    }

    & > .chat-input {
      width: 100%;
      height: 126px;
      box-sizing: border-box;
      border-top: 1px solid #e6e6e6;
      position: absolute;
      bottom: 0;

      & > .mute-status {
        background: #fff;
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        line-height: 126px;
      }

      & > textarea {
        box-sizing: border-box;
        width: 100%;
        height: 90px;
        border: none;
        outline: none;
        padding: 10px 16px;
      }

      & > .gray {
        opacity: .5;
      }

      & > .tool-box {
        width: 100%;
        height: 26px;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        & > .left {
          font-size: 12px;
          color: #999;
        }

        & > .right {
          display: flex;
          align-items: baseline;
          color: #ccc;
          font-size: 12px;

          & > div {
            cursor: pointer;
          }

          & > .emoji-icon {
            & > i {
              font-size: 18px;
            }
          }

          & > .pic-icon {
            margin: 0 10px;
            position: relative;

            & > #uploadImg {
              cursor: pointer;
              width: 100%;
              height: 100%;
              color: #fff;
              text-align: center;
              line-height: 25px;
              font-weight: bold;
              border-radius: 2px;
              position: absolute;
              left: 0;
              top: 0;
              cursor: pointer;
              border: none;
              outline: none;
              opacity: 0;
            }
          }

          & > .submit-btn {
            width: 62px;
            height: 26px;
            color: #fff;
            line-height: 26px;
            text-align: center;
            font-size: 14px;
            background: #29D087;
            border-radius: 2px;
          }
        }
      }
    }
  }

  @keyframes wordsLoop {
    0% {
      transform: translateX(200px);
      -webkit-transform: translateX(200px);
    }
    100% {
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
    }
  }
</style>
