/* eslint-disable */
import TRTC from 'trtc-js-sdk'

export default {

  createRTCClient(config) {
    return TRTC.createClient(config)
  },
  createRTCStream(userId,video, audio) {
    return TRTC.createStream({
      userId: userId+'',
      audio: audio,
      video: video
    })
  },

  // 获取当前网络传输状况

  getTransportStats() {
    window.trtcClient.getTransportStats().then(stats => {
      console.log('RTT: ' + stats.rtt)
    })
  },

  //
  getLocalAudioStats() {
    window.trtcClient.getLocalAudioStats().then(stats => {
      for (let userId in stats) {
        console.log('userId: ' + userId +
          ' bytesSent: ' + stats[userId].bytesSent +
          ' packetsSent: ' + stats[userId].packetsSent)
      }
    })
  },

  stopPush(trtcClient, stream) {
    console.log('停止播放的视频流：', stream)
    // 停止播放并删除相应<video>标签
    stream.stop()
    if (document.getElementById(stream.getId())) {
      document.getElementById(stream.getId()).remove()
    }
    return trtcClient.unpublish(stream)
  },

  //  获取媒体输入输出设备列表
  getDevices() {
    let list = []
    return TRTC.getMicrophones().then(res => {
      list = res
      return list
    }).catch(e => {
      console.log(e)
    })
    return list
  },

  // 获取 输出设备列表

  getSpeakers() {
    let list = []
    return TRTC.getSpeakers().then(res => {
      list = res
      return list
    }).catch(e => {
      console.log(e)
    })
    return list
  },

  // 获取 输出设备摄像头

  getCameras() {
    let list = []
    return TRTC.getCameras().then(res => {
      list = res
      return list
    }).catch(e => {
      console.log(e)
    })
    return list
  }
}
